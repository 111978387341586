import {
  DispatchScheduleLocation,
  LocationType
} from '@/shared/models/woSchedule.model';
import {
  convertDateFieldToAPIFormat,
  BS_DATEPICKER_FORMAT,
  DATE_VIEW_FULL_FORMAT
} from '@/utils/date.util';

export function getRowSchedules(row): DispatchScheduleLocation[] {
  if (!row) {
    throw new Error('Row has to be object with order data')
  }

  if (!Array.isArray(row.schedules)) {
    throw new Error('row.schedules  has to be array')
  }

  return row.schedules.map((schedule: DispatchScheduleLocation) => {
    const newSchedule: DispatchScheduleLocation = { ...schedule };

    if (newSchedule.scheduleIn === 'Invalid date') {
      newSchedule.scheduleIn = null;
    }

    if (newSchedule.locationType === LocationType.PULLOUT) {
      if (row.pulloutSchedule && row.pulloutSchedule !== 'Invalid date') {
        newSchedule.scheduleIn = row.pulloutSchedule;
        newSchedule.scheduleInTime = row.pulloutScheduleTime;
      }
    }

    if (newSchedule.locationType === LocationType.DELIVERY) {
      if (row.deliverySchedule && row.deliverySchedule !== 'Invalid date') {
        newSchedule.scheduleIn = row.deliverySchedule;
        newSchedule.scheduleInTime = row.deliveryScheduleTime;
      }
    }

    if (newSchedule.locationType === LocationType.PICKUP) {
      if (row.pickupSchedule && row.pickupSchedule !== 'Invalid date') {
        newSchedule.scheduleIn = row.pickupSchedule;
        newSchedule.scheduleInTime = row.pickupScheduleTime;
      }
    }

    if (newSchedule.locationType === LocationType.RETURN) {
      if (row.returnSchedule && row.returnSchedule !== 'Invalid date') {
        newSchedule.scheduleIn = row.returnSchedule;
        newSchedule.scheduleInTime = row.returnScheduleTime;
      }
    }

    if (
      typeof newSchedule.scheduleIn === 'string' &&
      newSchedule.scheduleIn.indexOf('-') !== -1
    ) {
      newSchedule.scheduleIn = convertDateFieldToAPIFormat(
        newSchedule.scheduleIn,
        BS_DATEPICKER_FORMAT
      );
    }

    if (
      typeof newSchedule.scheduleIn === 'string' &&
      newSchedule.scheduleIn.indexOf('/') !== -1
    ) {
      newSchedule.scheduleIn = convertDateFieldToAPIFormat(
        newSchedule.scheduleIn,
        DATE_VIEW_FULL_FORMAT
      );
    }

    if (newSchedule.scheduleIn && newSchedule.scheduleInTime) {
      const timeNumber = Number(newSchedule.scheduleInTime.replaceAll(':', ''));
      const scheduleDateNumber = Number(newSchedule.scheduleIn);

      if (!isNaN(timeNumber) && !isNaN(scheduleDateNumber)) {
        newSchedule.scheduleIn = `${scheduleDateNumber + timeNumber}`;
      }
    }
    newSchedule.scheduleInTime = undefined;

    return newSchedule;
  });
}
