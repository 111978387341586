import {
  convertDateFieldToAPIFormat,
  BS_DATEPICKER_WITH_TIME
} from './../../../../utils/date.util';
import { LocationType } from './../../../../shared/models/woSchedule.model';
import { Unavailable, Available, Delivery, Return } from './van-forms';

export enum VanType {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  DELIVERY = 'delivery',
  RETURN = 'return'
}

export const VanModels = {
  [VanType.UNAVAILABLE]: Unavailable,
  [VanType.AVAILABLE]: Available,
  [VanType.DELIVERY]: Delivery,
  [VanType.RETURN]: Return
};

export const VanLocationBuilder = {
  [VanType.UNAVAILABLE]: item => [
    {
      locationType: LocationType.PULLOUT,
      driverId: item.pulloutDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pulloutSchedule} ${item.pulloutScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [VanType.AVAILABLE]: item => [
    {
      locationType: LocationType.PULLOUT,
      driverId: item.pulloutDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pulloutSchedule} ${item.pulloutScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [VanType.DELIVERY]: item => [
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.PICKUP,
      driverId: item.pickupDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pickupSchedule} ${item.pickupScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [VanType.RETURN]: item => [
    {
      locationType: LocationType.PICKUP,
      driverId: item.pickupDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pickupSchedule} ${item.pickupScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.RETURN,
      driverId: item.returnDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.returnSchedule} ${item.returnScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ]
};
