class Unavailable {
  avaDsc: boolean = null;
  avaOth: boolean = null;
  pulloutSchedule: string | number = '';
  pulloutScheduleTime: string | number = '';
  pulloutDriver: string | number = '';
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
}

class Available {
  pulloutSchedule: string | number = '';
  pulloutScheduleTime: string | number = '';
  pulloutDriver: string | number = '';
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
  dropLive = '';
}

class Delivery {
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
  dropLive = '';
  pickupSchedule: string | number = '';
  pickupScheduleTime: string | number = '';
  pickupDriver: string | number = '';
}

class Return {
  pickupSchedule: string | number = '';
  pickupScheduleTime: string | number = '';
  pickupDriver: string | number = '';
  returnSchedule: string | number = '';
  returnScheduleTime: string | number = '';
  returnDriver: string | number = '';
}

export { Unavailable, Available, Delivery, Return };
